import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ClockIcon, CalenderIcon, PlusIcon } from '../../assets/icons';

interface IProps {
  callDate: string;
  auditDate: string
}

export function DetailsPageHeader({ auditDate, callDate }: IProps) {
  return (
    <Box className="flex justify-between w-full">
      <Box className=" flex gap-5 items-end">
        <Box className="flex  gap-2">
          <ClockIcon />
          <Typography variant="caption">
            {/* Duration:
            <span className="text-[#201F20] text-[16px] pl-2">{duration}</span> */}
            Call Date:
            <span className="text-[#201F20] text-[16px]"> {callDate}</span>
          </Typography>
        </Box>
        <Box className="flex gap-2 items-end">
          <CalenderIcon />
          <Typography variant="caption">
            {/* Date:
            <span className="text-[#201F20] text-[16px] pl-2">{date}</span> */}
            Audit Date:
            <span className="text-[#201F20] text-[16px]">{auditDate}</span>
          </Typography>
        </Box>
      </Box>
      <Box>
        <Button component="label" variant="contained" startIcon={<PlusIcon />}>
          Add Parameters
        </Button>
      </Box>
    </Box>
  );
}
