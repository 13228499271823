/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { PAGE_URL } from '../../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { CallIcon, DashboardIcon } from '../../assets/icons';
import { Typography } from '@mui/material';

export const NAVBAR_ITEMS: INavBarItem[] = [
  {
    element: <DashboardIcon className='w-7 h-7' />,
    name:'Dashboard',
    url: PAGE_URL.DASHBOARD,
  },
  {
    element: <CallIcon className='w-7 h-7' />,
    name:'Call List',
    url: PAGE_URL.CALL_AUDIT.LIST_PAGE,
  },
];

export function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const isPageActive = useCallback(
    (url: string) => {
      return url === location.pathname;
    },
    [location.pathname],
  );

  return (
    <aside className="h-[calc(100vh-74px)] flex flex-col gap-y-5 text-center pt-8 border-r border-y-0 border-l-0 border-solid border-white-medium">
      {NAVBAR_ITEMS.map((item) => (
        <a
          onClick={() => navigate(item.url)}
          key={item.url}
          className={`p-2 cursor-pointer border-solid border-l-0 border-y-0 border-r-[5px] flex items-center gap-1 flex-col ${
            isPageActive(item.url) ? 'text-[#1651D0] border-[#0D56BE]' : 'border-[transparent]'
          } `}
        >
          {item.element}<Typography variant="h5">{item.name}</Typography>
        </a>
      ))}
    </aside>
  );
}
