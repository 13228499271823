import React from 'react';
import { ITranscriptItem } from '../../pages/call-audit/staticData';
import { Transcript } from '../audio-player';

interface IProps {
  src: string;
  script: ITranscriptItem[];
}

export function CallDetailsContainer({ src, script }: IProps) {
  return <Transcript script={script} src={src} />;
}
