import React, { ComponentType, PropsWithChildren } from 'react';
import { GlobalContext, useAppGlobalContext } from './useGlobalContext';

export type IProps<T> = T & {};
export const GlobalContextWrapper = ({ children }: PropsWithChildren) => {
  const globalContextProps = useAppGlobalContext();
  return (
    <GlobalContext.Provider value={{ ...globalContextProps }}>
      {children}
    </GlobalContext.Provider>
  );
};

export function withGlobalContextWrapper<T>(
  WrappedComponent: ComponentType<IProps<T>>,
) {
  const Wrapper = (customProps: IProps<T>) => (
    <GlobalContextWrapper>
      <WrappedComponent {...customProps} />
    </GlobalContextWrapper>
  );
  return Wrapper;
}
