import React, { useState } from 'react';
import HeroBannerBg from '../../assets/images/LandingPage/hero-banner-bg.png';

interface HeaderProps {
  title: string;
  colortitle: string;
  description: string;
  showCTA?: boolean;
}

const LandingSubHeader: React.FC<HeaderProps> = ({
  title,
  colortitle,
  description,
  showCTA = false,
}) => {
  const [ctaVisible] = useState(showCTA);

  return (
    <div className="lp-sub-header px-6 py-14 md:py-0 md:min-h-48 lg:min-h-[311px] g-pc text-center relative">
      <div className="max-w-[920px]">
        <h1 className=" h1-b text-blue-dark space-x-2 capitalize">
          <span>{title}</span>
          <span className="text-pink-100">{colortitle}</span>
        </h1>
        <p className=" text-blue-dark body-l">{description}</p>
      </div>
      <div className="absolute z-[-1] inset-0">
        <img
          src={HeroBannerBg}
          className="object-cover size-full"
          alt="hero background image"
        />
      </div>

      {ctaVisible && (
        <div className=" space-x-3.5 mt-5 space-y-2">
          <a href={undefined} className="lp-btn cursor-pointer">
            Getting Started
          </a>
          <a href={undefined} className="lp-btn lp-btn-o cursor-pointer">
            Contact Sales
          </a>
        </div>
      )}
    </div>
  );
};

export default LandingSubHeader;
