import React from 'react';
import { IconsProps } from './Icons';

export const ErrorIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 25 25'}
      width={width ? width : '25'}
      height={height ? height : '25'}
      fill={fill ? fill : 'none'}
    >
     <path d="M11.251 10.5039H13.251V15.5039H11.251V10.5039ZM11.25 16.5039H13.25V18.5039H11.25V16.5039Z" fill="#FC5A5A"/>
<path d="M14.0179 4.70385C13.6699 4.04885 12.9919 3.64185 12.2499 3.64185C11.5079 3.64185 10.8299 4.04885 10.4819 4.70485L3.14395 18.5678C2.98112 18.8723 2.90046 19.2139 2.90993 19.559C2.9194 19.9041 3.01867 20.2408 3.19795 20.5358C3.37465 20.8322 3.62559 21.0774 3.926 21.2472C4.22641 21.4169 4.5659 21.5054 4.91095 21.5038H19.5889C20.2969 21.5038 20.9379 21.1418 21.3029 20.5358C21.4819 20.2407 21.581 19.904 21.5905 19.559C21.6 19.2139 21.5195 18.8724 21.3569 18.5678L14.0179 4.70385ZM4.91095 19.5038L12.2499 5.64085L19.5939 19.5038H4.91095Z" fill="#FC5A5A"/>
    </svg>
  );
};
