import React from 'react';
import { IconsProps } from './Icons';
export const GoogleDriveIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 33 33'}
      width={width ? width : '33'}
      height={height ? height : '33'}
      fill={fill ? fill : 'none'}
    >
      <circle
        cx="16.5"
        cy="16.5037"
        r="16"
        fill="url(#paint0_linear_214_643)"
      />
      <g clip-path="url(#clip0_214_643)">
        <path
          d="M8.43643 22.5528L9.27436 24.0004C9.44848 24.3052 9.69874 24.5447 9.99257 24.7189C10.8342 23.6503 11.4199 22.8303 11.7501 22.2589C12.085 21.6791 12.4967 20.772 12.9851 19.5378C11.6688 19.3645 10.6713 19.2778 9.99265 19.2778C9.34123 19.2778 8.34373 19.3645 7 19.5378C7 19.8752 7.08706 20.2126 7.26118 20.5174L8.43643 22.5528Z"
          fill="white"
        />
        <path
          d="M23.0075 24.7189C23.3014 24.5447 23.5517 24.3052 23.7258 24.0005L24.074 23.4019L25.739 20.5174C25.9099 20.2192 25.9999 19.8815 26.0001 19.5378C24.6486 19.3645 23.6529 19.2778 23.013 19.2778C22.3253 19.2778 21.3296 19.3645 20.0259 19.5378C20.5085 20.7787 20.9148 21.6858 21.2447 22.2589C21.5774 22.8371 22.165 23.6571 23.0075 24.7189Z"
          fill="#EA4335"
        />
        <path
          d="M16.5001 13.4423C17.4739 12.266 18.145 11.3589 18.5133 10.7212C18.81 10.2076 19.1365 9.38762 19.4927 8.26124C19.1989 8.08708 18.8616 8 18.5133 8H14.487C14.1387 8 13.8015 8.09799 13.5076 8.26124C13.9607 9.55309 14.3453 10.4725 14.6612 11.0194C15.0103 11.6238 15.6233 12.4314 16.5001 13.4423Z"
          fill="#00832D"
        />
        <path
          d="M20.015 19.5378H12.9852L9.99268 24.7189C10.2864 24.8931 10.6238 24.9801 10.972 24.9801H22.0282C22.3764 24.9801 22.7138 24.8822 23.0076 24.7188L20.015 19.5378Z"
          fill="#2684FC"
        />
        <path
          d="M16.5001 13.4422L13.5076 8.26123C13.2137 8.43539 12.9634 8.6748 12.7893 8.97961L7.26118 18.558C7.09026 18.8562 7.00023 19.1939 7 19.5377H12.9851L16.5001 13.4422Z"
          fill="#00AC47"
        />
        <path
          d="M22.9751 13.7689L20.211 8.97961C20.0369 8.6748 19.7866 8.43539 19.4927 8.26123L16.5002 13.4423L20.0151 19.5377H25.9894C25.9894 19.2002 25.9024 18.8629 25.7282 18.5581L22.9751 13.7689Z"
          fill="#FFBA00"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_214_643"
          x1="16.5"
          y1="0.503662"
          x2="16.5"
          y2="32.5037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E4EAF7" stop-opacity="0.49" />
          <stop offset="1" stop-color="#E4E6EB" stop-opacity="0.21" />
        </linearGradient>
        <clipPath id="clip0_214_643">
          <rect
            width="19"
            height="17"
            fill="white"
            transform="translate(7 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
